export const projects = [
  {
    id: 1,
    title: "Enkelhet ecommerce",
    description: `Enkelhet is an avant-garde e-commerce platform designed to
    optimize and improve the furniture shopping experience. Built upon
    cutting-edge technologies including Next.js, PostgreSQL, Tailwind
    CSS, and Three.js, Enkelhet seamlessly integrates advanced
    features to enable customers to visualize and purchase furniture
    in an entirely new dimension.`,
    image: "/images/enkelhet.gif",
    figureCaption: "Enkelhet ecommerce product page",
    tech: [
      "Next.JS v14 (app router)",
      "TypeScript",
      "Prisma",
      "PostgreSQL",
      "NextAuth",
      "Docker",
      "JWT (JSON Web Token)",
      "Zustand",
      "TailwindCSS",
      "PaypalAPI",
      "Three.JS",
      "React Three Fiber",
      "Cloudinary",
    ],
    date: "2024",
    liveUrl: "https://enkelhet-shop.vercel.app/",
    codeUrl: "https://github.com/tomasferrerasdev/enkelhet.shop",
  },
  {
    id: 2,
    title: "Eucalyptus landing",
    description: `Eucalyptus Card is a dynamic landing page project showcasing sleek, modern animations designed to captivate and engage visitors. The seamless transitions and interactive elements create an immersive user experience, highlighting the unique features and benefits of the Eucalyptus Card. Perfect for a visually appealing introduction to this innovative product.`,
    image: "/images/eucalyptus.gif",
    figureCaption: "Eucalyptus landing page",
    tech: [
      "Next.JS v14 (app router)",
      "TypeScript",
      "Zustand",
      "Lottie",
      "sass",
      "framer-motion",
    ],
    date: "2024",
    liveUrl: "https://eucalyptus-card.vercel.app/",
    codeUrl: "https://github.com/tomasferrerasdev/eucalyptus-card",
  },
  {
    id: 3,
    title: "Unbridled Spirit shop",
    description: `Unbridled Spirit is an e-commerce website that allow users to order Bourbon Online and have it delivered directly on their door.`,
    image: "/images/bourbon.png",
    figureCaption: "Unbridled Spirit admin page",
    tech: [
      "Next.JS v12 (page router)",
      "TypeScript",
      "MUI",
      "MongoDB",
      "Mongoose",
      "Docker",
      "JWT (JSON Web Token)",
      "NextAuth",
      "PaypalAPI",
    ],
    date: "2022",
    liveUrl: "https://unbridled-spirit.up.railway.app/",
    codeUrl: "https://github.com/tomasferrerasdev/unbridled-spirit",
  },
];
